// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.button
  reset-button()
  one-liner()
  width: 100%
  padding: 1px
  text-align: inherit
  transition: color $ad.s ease-in

  &:active,
  &-active
    color: $tc-deep-gray

.icon
  transition: transform $ad.m ease-in-out
  padding-left: $cs.xxs
