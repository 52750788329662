// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.tab
  display: flex
  flex-direction: column

  &:not(:last-child)
    margin-bottom: 0

.tab-item
  display: flex
  white-space: nowrap
  padding: $cs.m $cs.l
  text-align: center
  position: relative
  cursor: pointer
  user-select: none
  transition: color $ad.xs

  &-narrow
    padding: $cs.s $cs.m

  &-disabled
    cursor: not-allowed
    opacity: .4

  &-default:not(.tab-item-active)
    pseudo-border-animated(3px, $c-divider)

  &-active
    pseudo-border(3px, $c-active-blue)
    color: $tc-active
