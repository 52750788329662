// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.link
  one-liner()
  text-decoration: none
  color: $tc-subtle-gray
  min-height: 18px

  &:not(:last-child):after
    material-icon()
    margin: 0 $cs.xxs
    content: 'keyboard_arrow_right'
    color: $tc-subtle-gray

  +focus-visible()
    text-decoration: underline
    color: $tc-deep-gray


.last
  one-liner()
  color: $tc-deep-gray
  min-height: 18px
  +media-query($bp.s)
    &:last-child
      padding-right: $ls.xxs
