// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.list
  list-style-type: none
  padding: 0
  margin: 0
  overflow: hidden

  & > li:not(:last-child)
    margin-bottom: 0

  // We need to rely on the child list items for the reveal animation, the
  // height of the list is variable, making proper slide up transitions
  // impossible.
  & > li
    transition: height $ad.m ease-in-out

  &-nested > li
    height: 0
    transition: visibility $ad.m, height $ad.m ease-in-out

  &-nested:not(.list-expanded) > li
    visibility: hidden

  &-expanded > li
    height: 3.1rem

  &-expanded, &-nested
    & li a
      padding-left: $cs.l + $cs.l

.icon
  margin-right: $cs.xs
