// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

$border-width = $cs.xxs

left-border($color)
  background: linear-gradient(left, $color, 5px $color, 5px white)

.notification
  left-border($c-divider-dark)
  border-top: 1px solid $c-divider
  border-right: 1px solid $c-divider
  border-color: $c-divider-subtle
  border-radius: $br.m
  margin-bottom: $ls.xxs
  box-shadow: 0 1px 1px 0 $c-divider-dark
  display: flex
  flex-wrap: wrap
  padding: 1px

  &.small
    border-left-width: $border-width

    .icon
      margin-right: $cs.l - $border-width
      margin-left: 0

    .container
      margin: $cs.s $cs.s $cs.s $cs.l

  &.with-details
    .container
      margin-right: 8rem

  &.error
    left-border($c-error)
    color: $c-error

  &.warning
    left-border($c-warning)
    color: $tc-warning

  &.info
    left-border($c-active-blue)
    color: $c-active-blue

  &.success
    left-border($c-success)
    color: $c-success

  .title
    text-margin-bottom($cs.s)
    margin-top: 0
    font-weight: $fw.bold

  .content
    display: inline-block
    color: $tc-deep-gray

  .container
    display: flex
    margin: $cs.xl

  .icon
    display: flex
    margin-right: $cs.xl - $border-width
    margin-left: $cs.m
