// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: flex
  flex-wrap: nowrap
  margin-right: $cs.l
  align-items: center

  .message-count:last-child
    margin-left: $cs.m

.message-count
  display: inline-flex
  align-items: center
  flex-wrap: nowrap

  .icon
    nudge('up')
    color: $c-active-blue

.messages
  margin-left: $cs.m
  display: flex
  align-items: center

  +media-query($bp.xs)
    flex-basis: 100%

.status
  white-space: nowrap

  .date-time
    margin-left: $cs.xxs
