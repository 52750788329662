// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

$l = $cs.xl
$s = $cs.l
$xs = $cs.m

.box
  text-align: center
  display: block
  opacity: 0
  transition: opacity $ad.m

  &.visible
    opacity: 1

  &.micro
    height: $xs

  &.inline
    display: flex
    justify-content: flex-start
    align-items: center

    .spinner
      margin-right: $cs.xs

.spinner
  width: $l
  height: $l
  padding: 1px

.bar,
.circle
  stroke-width: 4
  fill: none
  vector-effect: non-scaling-stroke // @stylint ignore

.circle
  stroke: rgba(100, 100, 100, .1)

.bar
  stroke-dasharray: 40 50
  stroke-linecap: round

  animation: rotate .6s infinite linear

.stop
  stop-color: $c-active-blue

  .faded &
    stop-color: darken($c-subtle-fill, 20)

@keyframes rotate
  from
    transform: rotate(0)
  to
    transform: rotate(360deg)

.message
  color: $tc-subtle-gray

.center
  position: absolute
  top: ($l * -1) // To achieve visual centering.
  left: 0
  right: 0
  bottom: 0
  margin: auto
  height: $l

  &.small
    height: $s
    top: ($s * -1) // To achieve visual centering.

  &.micro
    height: $xs
    top: ($xs * -1) // To achieve visual centering.

.small .spinner
  width: $s
  height: @width

.micro .spinner
  width: $xs
  height: @width

  .bar,
  .circle
    stroke-width: 3.5
