// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: flex
  align-items: flex-end

  &:not(:last-child)
    margin-right: $cs.l
    +media-query($bp.s)
      margin-right: $cs.xs

  .message
    color: $tc-deep-gray

  &.error
    .icon,
    .value,
    .message
      color: $c-error

  &.error .message
    font-weight: $fw.bold

.icon
  nudge('up', 2px)
  color: $c-icon-fill
  margin-right: $cs.xs

.value
  color: $tc-deep-gray
  font-weight: $fw.bold
  margin-right: $cs.xxs

.container:not(.error)
  .message
    +media-query($bp.s)
      display: none
