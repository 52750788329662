// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  margin-top: $ls.s

.icon
  height: 4rem
  width: 3.5rem

.title-section-container
  display: flex
  flex-wrap: nowrap

.title-section
  display: inline-flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  padding-left: $cs.m

.title
  text-margin-top($ls.m)
  text-margin-bottom($cs.m)
  color: $tc-black
  font-weight: $fw.bolder
  font-size: $fs.xl
  margin: 0

.id
  color: $c-icon-fill

  &-prefix
    font-weight: $fw.bold

    &:after
      content: ':'
      padding-right: $cs.xxs
