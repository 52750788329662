// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.footer
  border-normal('top')
  height: $footer-height
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 $cs.xs
  color: $tc-subtle-gray
  flex: none
  background-color: white
  box-sizing: border-box

.link
  text-decoration: underline
  margin-left: $ls.xxs

  &:first-of-type
    margin-left: 0

  &:hover
    color: $tc-deep-gray

.version
  margin-left: $cs.xs
  &:not(:last-child)
    margin-right: $cs.xs

  +media-query($bp.xs3)
    display: none

.documentation
  &:not(:last-child)
    margin-right: $cs.xs

.claim
  +media-query($bp.s)
    display: none

.right
  display: flex
  align-items: center
