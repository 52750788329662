// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

$c-string = #f5a623
$c-number = #57a700
$c-boolean = $c-number
$c-invalid = $c-error

.json-payload
  display: inline-block
  font-family: $font-family-mono
  font-size: $fs.s
  color: $tc-black

  &-array
    &:before
      content: '[ '

    &:after
      content: ' ]'

  &-object
    &:before
      content: '{ '

    &:after
      content: ' }'

.string
  color: $c-string

.number
  color: $c-number

.boolean
  color: $c-boolean

.key
  padding-right: $cs.xs

  &:after
    content: ':'

.value
  &:not(:last-of-type)
    padding-right: $cs.xs

    &:after
      color: $tc-black
      content: ','

.invalid
  color: $c-invalid
