// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.table
  width: 100%
  table-layout: fixed

  tr
    height: 3rem

  th
    text-align: left
    font-weight: $fw.normal
    color: $tc-subtle-gray
    min-width: 6rem
    padding-right: $cs.xs

  +media-query($bp.m)
    margin-bottom: $ls.xs

tr.group-heading
  height: auto
  vertical-align: bottom

  &:not(:first-child)
    height: 3.5rem
  th
    color: $tc-deep-gray
    font-weight: $fw.bold
    width: 33%
    white-space: nowrap
    +media-query($bp.s)
      width: 50%

.sub th
  padding-left: $cs.l

.not-available
  color: $tc-subtle-gray
