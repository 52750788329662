// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: flex
  flex-wrap: wrap

  &:not(.no-prefix)
    :global(.select__value-container)
      font-family: $font-family-mono
      font-weight: $fw.bold
      font-size: $fs.s

  .select
    flex-basis: 100%
    margin-bottom: $cs.xs

    :global(.select__option):not(:first-child)
      font-family: $font-family-mono
      font-weight: $fw.bold
      font-size: $fs.s

  .byte
    flex-basis: 100%

.zeroFillButton
  font-family: $font-family-mono
  font-size: $fs.s

+media-query-min($bp.s)
  .container
    flex-wrap: nowrap

    .select
      flex-basis: 30%
      margin-right: $cs.xs
      margin-bottom: 0

    .select + .byte
      flex-basis: 70%
