// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

$button($color)
  reset-button()
  one-liner()
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  border-radius: $br.s
  padding: 0 $cs.s
  height: $default-input-height
  outline: 0
  font-weight: $fw.bold
  cursor: pointer
  color: white

  &.large
    height: auto
    padding: $cs.m
    border-radius: $br.m
    min-width: 6rem

  &:not(.busy) span:last-child
    vertical-align: middle
    nudge('up')

  &:not(:last-child)
    margin-right: $cs.s

  &:not(.naked)
    background: $color

    &:hover
      background-color: hoverize($color)

    +focus-visible()
      background-color: hoverize($color)

    &:active
      background-color: activize($color)
      transition-duration: $ad.xs

  &:disabled:not(.busy), &.disabled
    opacity: .4
    cursor: default

    &:hover:not(.naked):not(.busy)
      background: $color
      cursor: default

  &.raw:not(.busy)
    border-dark()
    color: $c-icon-fill

  &.naked
    transition: background-color $ad.s
    background-color: transparent
    font-weight: $fw.normal
    border: 1px solid transparent

    &:not(.busy)
      color: $color

    &:not(.busy):not(:active):not(:disabled)
      &:hover
        background-color: $c-backdrop
      +focus-visible()
        background-color: $c-backdrop

  &.busy:not(.naked),
  &.busy:disabled
    position: relative
    if ($color != transparent)
      background: $c-backdrop
      color: transparent

      $s = lighter($color, 1)
    else
      $s = lighter($c-active-blue, 1)

    svg
      center-absolute()

    stop
      stop-color: $s

.button
  font: inherit
  font-size: 1rem
  transition: color $ad.s, background $ad.s, border $ad.s, opacity $ad.s
  text-decoration: none

  $button($c-active-blue)

  &.danger
    $button($c-error)

  &.warning
    &:not(.naked)
      $button($c-warning)
    &.naked
      $button($tc-warning)

  &.secondary
    &:not(.naked)
      $button($c-subtle-fill)
    &.naked
      $button($tc-subtle-gray)

  &.raw
    $button(white)

  &.error
    animation: shake .15s 4 linear

  &.with-icon
    padding-left: $cs.xs

    span:first-child
      margin-right: $cs.xxs

    &.large
      padding-left: $cs.s

      span:first-child
        margin-right: $cs.xs

    &.responsive-label
      +media-query($bp.xxs)
        span:first-child
          margin-left: 0
          margin-right: 0

        .link-button-message
          display: none

  &.only-icon
    padding-left: $cs.xs
    padding-right: $cs.xs

    &.large
      padding-left: $cs.s
      padding-right: $cs.s

@keyframes shake
  0%
    transform: translate(0)
  25%
    transform: translate(.4em)
  75%
    transform: translate(-.4em)
  100%
    transform: translate(0)

.content
  position: relative
  vertical-align: middle

.spinner
  position: absolute
  top: 0
  bottom: 0
  left: $cs.s * -1
  right: $cs.s * -1
