// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.configuration-section
  display: flex
  justify-content: flex-start
  flex-direction: row

  .select
    width: auto
    padding-right: $cs.s

    &.(:last-child)
      padding-right: 0

    &-xs
      flex-basis: $fiw.xs
    &-s
      flex-basis: $fiw.s

  +media-query($bp.s)
    flex-direction: column

    .select
      padding-right: 0

      &-xs,
      &-s
        flex-basis: auto

.h-rule
  margin-top: $ls.xs
  margin-bottom: $ls.s
