// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: flex
  align-items: center
  flex: 0 0 auto

  img
    height: 100%
    width: 100%
    object-fit: contain

  .logo-container
    display: flex
    align-items: center
    line-height: 0
    height: 2.3rem
    padding: $cs.m 0

    img
      width: 100%

    +media-query($bp.s)
      width: 9rem
      height: 2.1rem

  .logo
    display: flex
    align-items: center

  .brand-logo
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    max-width: 9rem

    +media-query-min($bp.s)
      height: 3rem

    +media-query($bp.s)
      height: 2rem

    &-container
      display: flex
      align-items: center
      height: 100%
      width: 100%

  &:not(.vertical)
    &:not(.custom-branding)
      .logo-container
        width: 13rem
        +media-query($bp.s)
          width: 12rem

    &.custom-branding .logo
      display: flex
      align-items: center
      padding-left: 'calc(%s - .15rem)' % $ls.xs
      +media-query($bp.s)
        padding-left: $cs.s

      .logo-container
        nudge('down', .15rem)
        width: 11rem
        +media-query($bp.s)
          width: 8rem

    .brand-logo
      border-dark('right')
      align-items: center
      padding-right: $ls.xs
      +media-query($bp.s)
        padding-right: $cs.s

      &-container
        +media-query($bp.s)
          max-width: 6rem
          height: 2.5rem

          img
            width: 100%

        img
          object-position: left

  &.vertical
    width: 15rem
    flex-direction: column
    margin-bottom: $ls.s

    &.custom-branding .logo
      border-dark('top')
      padding-top: $ls.xs
      margin-top: $ls.xs
      height: auto
      width: 100%

      &-container
        width: 100%
        height: auto

    .brand-logo
      margin-left: auto
      margin-right: auto
      width: 100%

      &-container
        display: block
        max-height: 3rem
        max-width: 10rem
        width: 100%

        img
          object-position: center
          width: 100%
